import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Whatsapp from "./Components/Whatsapp";
import ContactUsButton from "./Components/ContactUsButton";
import BookapoointmentButton from "./Components/BookapoointmentButton";

const LadningPage = lazy(() => import("./Pages/LandingPage/LadningPage"));
const Treatment = lazy(() => import("./Pages/TreatmentPage/Treatment"));
const Docter = lazy(() => import("./Pages/DocterPage/Docter"));
const BookAppoinment = lazy(() =>
  import("./Pages/BookAppoinment/BookAppoinment")
);
const About = lazy(() => import("./Pages/About/About"));
const Pharmacy = lazy(() => import("./Pages/Pharmacy/Pharmacy"));
const Labs = lazy(() => import("./Pages/Labs/Labs"));
const Department = lazy(() => import("./Components/Depts/Proctology"));
const ENT = lazy(() => import("./Components/Depts/Ent"));
const Laparoscopy = lazy(() => import("./Components/Depts/Laparoscopy"));
const Gynecology = lazy(() => import("./Components/Depts/gynecology.jsx"));
const Vascular = lazy(() => import("./Components/Depts/Vascular.jsx"));
const Urology = lazy(() => import("./Components/Depts/Urology.jsx"));
const ChooseDoctor = lazy(() => import("./Components/Tabs/ChooseDoctor.jsx"));
const Scheduler = lazy(() => import("./Components/Scheduler/Scheduler.jsx"));
const AddtoCart = lazy(() => import("./Pages/AddToCart/AddToCart"));
const ProductDetails = lazy(() => import("./Pages/Pharmacy/ProductDetails"));
const DoctorProfile = lazy(() =>
  import("./Pages/Doctor-Profile/DoctorProfile")
);
const Blog = lazy(() => import("./Pages/Blog/Blog"));
const Careers = lazy(() => import("./Pages/Careers/Careers"));
const SingleBlog = lazy(() => import("./Pages/SingleBlog/SingleBlog"));
const PaymentSuccess = lazy(() =>
  import("./Pages/PaymentSuccess/PaymentSuccess")
);
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));
const PharmacyCategoriesFilterPage = lazy(() =>
  import("./Pages/Pharmacy/PharmacyCategoriesFilter")
);
const LabHealthCheckFilterPage = lazy(() =>
  import("./Pages/Labs/LabHealthCheckFilterPage")
);
const LabTopBookedTestsFilter = lazy(() =>
  import("./Pages/Labs/LabTopBookedTestsFilter")
);
const LabPackagesFilterPage = lazy(() =>
  import("./Pages/Labs/LabPackagesFilterPage")
);
const LabSinglePackage = lazy(() => import("./Pages/Labs/LabSinglePackage"));
const LabSingleTest = lazy(() => import("./Pages/Labs/LabSingleTest"));
const PharmacySingleProduct = lazy(() =>
  import("./Pages/Pharmacy/PharmacySingleProduct")
);
const AddToCartLab = lazy(() => import("./Pages/AddToCart/AddToCartLab"));
const AddToCartPharma = lazy(() => import("./Pages/AddToCart/AddToCartPharma"));
const DoctorLabTestPayLink = lazy(() =>
  import("./Pages/PaymentLinkPage/DocterLabTestPayLink")
);
const PrivacyPolicy = lazy(() =>
  import("./Pages/Privacy Policy/PrivacyPolicy.jsx")
);
const Termsandconditions = lazy(() =>
  import("./Pages/TermsAndConditions/Termsandconditions.jsx")
);
const Refundpolicy = lazy(() =>
  import("./Pages/Refundpolicy/Refundpolicy.jsx")
);
const TreatmentBySlug = lazy(() =>
  import("./Pages/TreatmentPage/TreatmentBySlug.jsx")
);
const Category = lazy(() => import("./Pages/BlogCat/BlogCat.jsx"));

function App() {
  return (
    <div className="">
      <Router>
        <Whatsapp />
        <BookapoointmentButton />
        <ContactUsButton />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route exact path="/" element={<LadningPage />} />
              <Route
                path="/treatmentname/:treatmentnameslug"
                element={<TreatmentBySlug />}
              />
              <Route path="/doctors" element={<Docter />} />
              <Route path="/about" element={<About />} />
              <Route path="/labs" element={<Labs />} />
              <Route
                path="/lab-test-category/:categorynameslug"
                element={<LabHealthCheckFilterPage />}
              />
              <Route
                path="/lab-tests/top-booked-tests"
                element={<LabTopBookedTestsFilter />}
              />
              <Route
                path="/lab-tests/lab-health-packages"
                element={<LabPackagesFilterPage />}
              />
              
              <Route
                path="/lab-tests/labpackage/:packagenameslug"
                element={<LabSinglePackage />}
              />
              <Route
                path="/lab-tests/labtest/:testnameslug"
                element={<LabSingleTest />}
              />
              <Route
                path="/department/:departmentnameslug"
                element={<Department />}
              />
              <Route path="/singleblog/:blogslug" element={<SingleBlog />} />
              <Route path="/choosedoctor" element={<ChooseDoctor />} />
              <Route path="/pharmacy" element={<Pharmacy />} />
              <Route
                path="/shop-by-category/:categorynameslug"
                element={<PharmacyCategoriesFilterPage />}
              />
              <Route path="/blogs/:cat" element={<Category />} />
              <Route
                path="/medicine/:medname"
                element={<PharmacySingleProduct />}
              />
              <Route path="/bookappoinment" element={<BookAppoinment />} />
              <Route path="/addtocart" element={<AddtoCart />} />
              <Route path="/addtocartlab" element={<AddToCartLab />} />
              <Route path="/addtocartpharma" element={<AddToCartPharma />} />
              <Route path="/product-details" element={<ProductDetails />} />
              <Route
                path="/docprofile/:doctornameslug"
                element={<DoctorProfile />}
              />
              <Route path="/blog" element={<Blog />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/paymentsuccess" element={<PaymentSuccess />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route
                path="/doctortest/paymentlink/:testid"
                element={<DoctorLabTestPayLink />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/termsandcondition"
                element={<Termsandconditions />}
              />
              <Route path="/refundpolicy" element={<Refundpolicy />} />
            </Routes>
          </Suspense>
        </main>
      </Router>
    </div>
  );
}

export default App;
