import { axiosPrivate, axiosPublic } from "./axios";

// Deaprtment
export const getAllDepartments = async () => {
  let res = await axiosPrivate.get("/department/get_department");
  return res.data;
};

export const getOneDepartment = async (departmentId) => {
  let res = await axiosPrivate.get(
    `/department/get_department/${departmentId}`
  );
  return res.data;
};

// Homepage Doctors API
export const getAllDoctors = async () => {
  let res = await axiosPrivate.get("/doctor/get_doctor");
  return res.data;
};
export const getDoctorsByDepartment = async (departmentid) => {
  let res = await axiosPrivate.get(`/doctors/${departmentid}`);
  return res.data;
};
export const getDoctorById = async (doctorid) => {
  let res = await axiosPrivate.get(`/doctor/get_doctor/${doctorid}`);
  return res.data;
};

// Homepage Offerslider Image
export const offerSliderImages = async () => {
  let res = await axiosPrivate.get("/homepage/get_homepageslider");
  return res.data;
};

export const offerSliderImagesLabPage = async () => {
  let res = await axiosPrivate.get(
    "/offerslider/get_offerslider?offer_type=Lab"
  );
  return res.data;
};

export const offerSliderImagesPharmacyPage = async () => {
  let res = await axiosPrivate.get(
    "/offerslider/get_offerslider?offer_type=Pharmacy"
  );
  return res.data;
};

export const offerSliderImagesConsultationPage = async () => {
  let res = await axiosPrivate.get(
    "/offerslider/get_offerslider?offer_type=Consultation"
  );
  return res.data;
};

//Get Insurance Slider
export const getInsuranceSlider = async () => {
  let res = await axiosPrivate.get(`/insurance/get_insuranceslider`);
  return res.data;
};

// Get all sympoms
export const getNewSymptoms = async () => {
  let res = await axiosPrivate.get(`/getnewsymptoms`);
  return res.data;
};

//HomePage FAQ

export const getHomePageFAQ = async () => {
  let res = await axiosPrivate.get(`/homepage/get_faq`);
  return res.data;
};

// Sub Department
export const getOneSubdepartMent = async (subdepartmentId) => {
  let res = await axiosPrivate.get(
    `/department/get_subdepartment/${subdepartmentId}`
  );
  return res.data;
};

export const getContentSubdepartMent = async (subdepartmentId) => {
  let res = await axiosPrivate.get(`/content/${subdepartmentId}`);
  return res.data;
};

export const getPackageOfSubdepartment = async (subdepartmentId) => {
  let res = await axiosPrivate.get(`/packages/${subdepartmentId}`);
  return res.data;
};

export const getFaqSubDepartment = async (subDepartmentId) => {
  let res = await axiosPrivate.get(`/faq/${subDepartmentId}`);
  return res.data;
};

export const getSubdepartmentsUnderOneDepartment = async (departmentId) => {
  let res = await axiosPrivate.get(`/subdepartments/${departmentId}`);
  return res.data;
};

// Book Appointment

export const addAppointment = async (data) => {
  let res = await axiosPrivate.post(`/appointment/add_appointment`, data);
  return res.data;
};

export const getSchedular = async (doctorId) => {
  let res = await axiosPrivate.get(`/doctorschedule/${doctorId}`);
  return res.data;
};

// Labs
export const getListOfAllTestOfLabs = async () => {
  let res = await axiosPrivate.get(`/labtest/get_labtest`);
  return res.data;
};

// Pharmacy
export const getListOfAllMedicines = async () => {
  let res = await axiosPrivate.get(`/medPhram/get_medPhram`);
  return res.data;
};

// lead create
export const createLead = async (data) => {
  let res = await axiosPrivate.post(`/booking/create_booking`, data);
  return res.data;
};

// leadUpdate
export const updateLead = async (id, data) => {
  let res = await axiosPrivate.put(`/booking/update_booking/${id}`, data);
  return res.data;
};

// leads otp verification
export const leadOtpVerification = async (id, data) => {
  let res = await axiosPrivate.post(`/booking/otp_verify/${id}`, data);
  return res.data;
};

// Razorpay Integration

export const createOrder = async (amount) => {
  let res = await axiosPrivate.post(`/payment_order`, amount);
  return res.data;
};

export const verifyPayment = async (data) => {
  let res = await axiosPrivate.post(`/payment_success`, data);
  return res.data;
};

// Add to cart
export const addToCart = async (data) => {
  let res = await axiosPrivate.post(`/cart/add_cart`, data);
  return res.data;
};

// get About us
export const getAboutUs = async () => {
  const res = await axiosPrivate.get(`/about/get_about`);
  return res.data;
};

export const getAllIcons = async () => {
  const res = await axiosPrivate.get(`/icon/get_icon`);
  return res.data;
};

// Home page counts

export const getDiseaseCount = async () => {
  const res = await axiosPrivate.get(`/homepage_disease_count`);
  return res.data;
};

export const getDoctorsCount = async () => {
  const res = await axiosPrivate.get(`/homepage_doctor_count`);
  return res.data;
};

export const getHappyPatientsCount = async () => {
  const res = await axiosPrivate.get(`/happy_patient`);
  return res.data;
};

export const getSuccessfulSurgeries = async () => {
  const res = await axiosPrivate.get(`/surgeries_count`);
  return res.data;
};

export const getCallUs = async () => {
  const res = await axiosPrivate.get(`/getcallus`);
  return res.data;
};

export const getWhyLifePlus = async () => {
  const res = await axiosPrivate.get(`/getdescription`);
  return res.data;
};

export const getFooter = async () => {
  const res = await axiosPrivate.get(`/footer/get_homepagefooter`);
  return res.data;
};

export const getHomePageCallUs = async () => {
  const res = await axiosPrivate.get(`/gethomepagecallus`);
  return res.data;
};

export const getTestimonials = async () => {
  const res = await axiosPrivate.get(`/gettestinomal`);
  return res.data;
};

// Contact Us

export const contactUsBannerandLocation = async () => {
  const res = await axiosPrivate.get(`/getcontact`);
  return res.data;
};

export const contactUsForm = async (data) => {
  const res = await axiosPrivate.post(`/contactform/add_contactform`, data);
  return res.data;
};

// Lab package section
//cancelled this api
export const getPachageCategory = async () => {
  const res = await axiosPrivate.get(`/getcategoryhealth`);
  return res.data;
};
export const getHealthPackageCategoryWise = async (categoryId) => {
  const res = await axiosPrivate.get(`/categorywise_labpackages/${categoryId}`);
  return res.data;
};
//updated this api
export const gethealthPackages = async () => {
  const res = await axiosPrivate.get(`/gethealthpackages`);
  return res.data;
};
// get single health package
export const getSingleHealthPackage = async (packageId) => {
  const res = await axiosPrivate.get(`/gethealthpackages/${packageId}`);
  return res.data;
};

// Pharmacy Category
export const getPharmacyCategory = async () => {
  const res = await axiosPrivate.get(`/getcategorypharmacy`);
  return res.data;
};

export const getPharmacyPackages = async () => {
  const res = await axiosPrivate.get(`/getpharmacypackages`);
  return res.data;
};

export const getMedicinesByPharmacyCatId = async (categoryId) => {
  const res = await axiosPrivate.get(
    `/categorywise_pharmacypackages/${categoryId}`
  );
  return res.data;
};

export const getTopBookedTests = async () => {
  const res = await axiosPrivate.get(`/labtest/get_labtest`);
  return res.data;
};

export const getSingleLabtest = async (testId) => {
  const res = await axiosPrivate.get(`/labtest/get_labtest/${testId}`);
  return res.data;
};

// get lab tests by category
export const getLabTestByCategory = async (LabcategoryId) => {
  const res = await axiosPrivate.get(`/labtestbycategory/${LabcategoryId}`);
  return res.data;
};

// get Medicines by category
export const getMedicinesByCategory = async (pharmCatId) => {
  const res = await axiosPrivate.get(`/category_wise_medicine/${pharmCatId}`);
  return res.data;
};

// get medicine by medicineId
export const getMedicineById = async (medId) => {
  const res = await axiosPrivate.get(`/medPhram/get_medPhram/${medId}`);
  return res.data;
};

// add to cart pharmacy
export const addtocartPharmacy = async (pdata) => {
  const res = await axiosPrivate.post(`/pharmacycart/add_pharmacycart`, pdata);
  return res.data;
};

// add to cart Lab
export const addToCartLab = async (ldata) => {
  const res = await axiosPrivate.post(`/labcart/add_labcart`, ldata);
  return res.data;
};

// add to cart package
export const addToCartPackages = async (formdata) => {
  const res = await axiosPrivate.post(
    `/healthpackagescart/add_healthpackagescart`,
    formdata
  );
  return res.data;
};

// get doctor test - [Direct Payment Link]
export const getDoctorSingleTest = async (testid) => {
  const res = await axiosPrivate.get(`/getdoctortest/${testid}`);
  return res.data;
};

// add online lab test appointment

export const CreatelabOnlineAppointMent = async (formData) => {
  const res = await axiosPrivate.post(`/online/add_onlinelab`, formData);
  return res.data;
};

// Privacy Policy
export const getPrivacyPolicy = async () => {
  const res = await axiosPrivate.get("/getprivaypolicy");
  return res.data;
};

// Terms And Condition
export const getTermsAndCondition = async () => {
  const res = await axiosPrivate.get("/gettermscondition");
  return res.data;
};

// Refund Policy/ cancellation policy
export const getRefundPolicy = async () => {
  const res = await axiosPrivate.get("/getcancellationpolicy");
  return res.data;
};

// New Slugs for All Pages where Id
export const getSectionForTreatmentbySlug = async (treatmentNameSlug) => {
  const res = await axiosPrivate.get(
    "/department/get_subdepartmentone/" + treatmentNameSlug
  );
  return res.data;
};

export const getDoctorsBySubdepartmentSlug = async (treatmentNameSlug) => {
  const res = await axiosPrivate.get(
    `/doctorbysubdepartment/${treatmentNameSlug}`
  );
  return res.data;
};

export const getContentBySubdepartmentslug = async (treatmentNameSlug) => {
  const res = await axiosPrivate.get(
    `/contentbysubdepartmentname/${treatmentNameSlug}`
  );
  return res.data;
};

export const getTreatmentPackagesByTreatmentSlug = async (
  treatmentNameSlug
) => {
  const res = await axiosPrivate.get(
    `/packagebysubdepartmentname/${treatmentNameSlug}`
  );
  return res.data;
};

export const getFAQByTreatmentSlug = async (treatmentNameSlug) => {
  const res = await axiosPrivate.get(
    `/faqbysubdepartmentname/${treatmentNameSlug}`
  );
  return res.data;
};

// Slugs for Department ---------

export const getDepartmentByDepartmentSlug = async (departmentnameslug) => {
  const res = await axiosPrivate.get(
    `/department/get_departmentone/${departmentnameslug}`
  );
  return res.data;
};

export const getDoctorsByDepartmentSlug = async (departmentnameslug) => {
  const res = await axiosPrivate.get(
    `/doctorbydepartment/${departmentnameslug}`
  );
  return res.data;
};

export const getTreatmentByDepartmentSlug = async (departmentnameslug) => {
  const res = await axiosPrivate.get(
    `/treatmentbydepartmentname/${departmentnameslug}`
  );
  return res.data;
};

//  Doctors By Doctor Name slugs -

export const getDoctorByDoctorNameSlug = async (doctorNameSlug) => {
  const res = await axiosPrivate.get(`/doctor/get_doctorone/${doctorNameSlug}`);
  return res.data;
};

//  Medicine -
export const getMedicineByMendicineName = async (medicineNameSlug) => {
  const res = await axiosPrivate.get(
    `/medicine/getonemedicine/${medicineNameSlug}`
  );
  
  return res.data;
};

export const getMedicineByCategorySlug = async (medicineNameSlug) => {
  const res  = await axiosPrivate.get(`/category_medicine/${medicineNameSlug}`);
  return res.data;
}

export const getMultipleTestsbyCategorySlug = async (labCatNameSlug) => {
  const res = await axiosPrivate.get(`/labtestusingcategory/${labCatNameSlug}`);
  return res.data
}

export const getTestByTestName = async (labtestName) => {
  const res = await axiosPrivate.get(`/labtest/getonelabtestt/${labtestName}`);
  return res.data;
} 

export const getHealthPackageBySlug = async (healthpackageslug) =>{
  const res = await axiosPrivate.get(`/getonehealthpackages/${healthpackageslug}`);
  return res.data;
}

export const getMetaTitleAndDescriptionForHome = async () => {
  const res = await axiosPrivate.get(`/newmetatag/get_newmetatag`);
  return res.data;
};

export const getMetaTagsForHomePage = async () => {
  const res = await axiosPrivate.get(`/metatag/get_metatag`);
  return res.data;
};
